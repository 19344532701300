import React, {useEffect} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import {bindActionCreators} from "redux";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";
function InyettDetails({ customer, vbCustomer, customerActions, dataLoading, inyettData }) {
    const {t} = useTranslation();

    useEffect(() => {
        if (!inyettData) {
            customerActions.getInyettData(vbCustomer?.organizationNumber ?? customer.vossCustomer?.organizationNumber);
        }
    }, []);

    if (dataLoading) {
        return <div className="spinner spinner-default-green m-auto d-block"/>
    }

    return <div className="table-responsive">
        <table className={`table table-stripe`}>
            <tbody>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.employees')}</th>
                <td>{inyettData?.employeeAmount}</td>
            </tr>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.revenue')}</th>
                <td>{inyettData?.netRevenue ? inyettData.netRevenue : "-" + " tKr"}</td>
            </tr>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.type')}</th>
                <td>{inyettData?.type}</td>
            </tr>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.branch')}</th>
                <td>{inyettData?.branch}</td>
            </tr>
            </tbody>
        </table>
    </div>
}
function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        inyettData: state.CustomerReducer.inyettData,
        dataLoading: state.CommonReducer.dataLoading.inyettData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InyettDetails));