import {useTranslation, withTranslation} from "react-i18next";
import React, {useEffect} from "react";
import Modal from "../../Modal";
import {ActionType} from "../../../Constants/actionType";
import {bindActionCreators} from "redux";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";
import DateTimeInput from "../../FormComponents/Input/DateTimeInput";
import moment from "moment";

const EditExpiryDateModal = ({form, customer, commonActions, customerActions, onClose }) => {
    const {t} = useTranslation();

    useEffect(() => {
        commonActions.startFormEditing("vossCustomerExpiryDateFormData", customer.vossCustomer)

        return () => {
            commonActions.cancelFormEditing("vossCustomerExpiryDateFormData");
        }
    }, []);

    const handleCloseClick = () => {
        commonActions.cancelFormEditing(form.id, onClose);
    }
    const onSave = () => {
        customerActions.saveVossCustomer(form, ActionType.Edit, handleCloseClick);
    }


    const modalProps = {
        title: `Edit customer: ${customer.vossCustomer.name}`,
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => handleCloseClick(),
        onSaveClick: onSave,
        mainButtonTitle: t("common.save"),
    }

    const yesterday = moment().subtract( 1, 'day' );
    const oneYearFromToday = moment().add( 1, 'year');
    const isValidExpiryDate = (current) => {
        return current.isAfter(yesterday) && current.isBefore(oneYearFromToday);
    }

    return form && form.data && <Modal {...modalProps}>
        <form className="skynet-form container">
            <fieldset>
                <DateTimeInput dateFormat={"YYYY-MM-DD"} showTime={false}
                               onChange={(value) => commonActions.changeMultiFormField(form.id, "expiryDate", value)} initialValue={customer.vossCustomer.expiryDate}
                               isValidDate={isValidExpiryDate}
                               label="Expiry date"/>
            </fieldset>
        </form></Modal>
}


function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        form: state.CommonReducer.forms && state.CommonReducer.forms.vossCustomerExpiryDateFormData,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditExpiryDateModal));
