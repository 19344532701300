import React, { useState } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import helpers from '../../../Utils/helpers'
import {hasAdminAccess} from "../../../Utils/permissionUtils";
import {UserRoles} from "../../../Constants/userRoles";
import {InfoLabel} from "../../Badges/InfoLabel";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import targetGroupHelpers, {isActive} from "../../../Utils/targetGroups";
import {targetGroupConfigurations} from "../../../Constants/VOSS/targetGroups";

export function InternalInfo({ vbCustomer, customer, dropdownlists, user, toggleModal, targetGroups }) {
    const paymentTerms = [
        { id: 30, name: "30 dagar netto" },
        { id: 2, name: "2 postförskott" }]
    const customerGroups = dropdownlists && dropdownlists.CustomerGroup;
    const customerTypes = dropdownlists && dropdownlists.CustomerType;
    const customerStatuses = dropdownlists && dropdownlists.CustomerStatus;
    const customerGroups2 = dropdownlists && dropdownlists.CustomerGroup2;
    const spcsEdi = dropdownlists && dropdownlists.SpcsEdi;
    const vbAdmin = hasAdminAccess(user, UserRoles.InternalInfo)

    const { t } = useTranslation();

    const getCustomerInvoiceFee = () => {

        switch (vbCustomer.invoiceFee) {
            case 8192:
                return "Yes"
            case 8200:
                return "No"
            default:
                return vbCustomer.invoiceFee
        }
    }

    function getCustomerInvoiceFees(vbCustomer) {
        var invoiceFees = [
            { id: 8192, name: "Yes" },
            { id: 8200, name: "No" }
        ];

        if (invoiceFees.filter(item => item.id == vbCustomer.invoiceFee).length == 0) {
            invoiceFees.push({ id: vbCustomer.invoiceFee, name: vbCustomer.invoiceFee });
        }

        return invoiceFees;
    }

    const formatGroupName = (group) => {
        if (group?.id > 0) {
            return `${group.name} / ${group.id}`;
        }
    }

    function renderField(label, value) {
        return (<>
                <th className="bold">{label}</th>
                <td>{value}</td>
            </>
        )
    }

    // VOSS: Company created,Customer is using 2FA
    // CustomerType -> Target group
    return (<div className="table-responsive">
            <table className="table table-stripe">
                <tbody>
                {customer?.targetGroups &&
                    <tr>
                        <th className="bold">Target groups</th>
                        <td colSpan={3}>
                            {customer?.targetGroups?.map((targetGroup) => {
                                return <div key={targetGroup.id} className="d-inline-block position-relative infolabel pr-8 py-4">
                                        <InfoLabel title={targetGroup.name}/>
                                    </div>
                            })}
                        </td>
                    </tr>
                }
                <tr>
                    {renderField(t('internalinfocontainer.customergroup'),vbCustomer.group !== 0 && `${helpers.getDropdownItemName(customerGroups, vbCustomer.group)} / ${vbCustomer.group}`, customerGroups, formatGroupName(vbCustomer.group))}
                    {renderField(t('internalinfocontainer.voncustomergroup'), vbCustomer.vonGroup !== 0 && `${helpers.getDropdownItemName(customerGroups, vbCustomer.vonGroup)} / ${vbCustomer.vonGroup}` || "-")}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.customergroup2'), vbCustomer.secondaryGroup !== 0 && `${helpers.getDropdownItemName(customerGroups2, vbCustomer.secondaryGroup)} / ${vbCustomer.secondaryGroup}` || "Empty", customerGroups2)}
                    {renderField(t('internalinfocontainer.customertype'), helpers.getDropdownItemName(customerTypes, vbCustomer.type))}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.customerstatus'), helpers.getDropdownItemName(customerStatuses, vbCustomer.status), customerStatuses)}
                    {renderField(t('internalinfocontainer.creditlimit'), vbCustomer.creditLimit)}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.invoicefee'), getCustomerInvoiceFee(vbCustomer), getCustomerInvoiceFees(vbCustomer))}
                    {renderField(t('internalinfocontainer.spcsedi'), helpers.getDropdownItemName(spcsEdi, vbCustomer.spcsEdi) || "-")}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.responsibleperson'), vbCustomer.responsibleSalesPersonName || "-",)}
                    {renderField(t('internalinfocontainer.paymentterms'), helpers.getDropdownItemName(paymentTerms, vbCustomer.paymentTerms))}
                </tr>
                {targetGroupHelpers.isActive(targetGroups, customer?.targetGroups, targetGroupConfigurations.expiryDateRequired) &&
                <tr>
                    <th className="bold">Expiry date</th>
                    <td>
                        {helpers.formatDate(customer?.expiryDate)}<SkynetIcon icon="vismaicon-sm vismaicon-edit float-right" onClick={() => toggleModal("editExpiryDateModal", "editExpiryDateModal")}/>
                    </td>
                </tr>}
                </tbody>
            </table>
        </div>
    )
}
