import { useTranslation } from "react-i18next";
import { FormInput} from '../../FormComponents/Input/Input';
import {ActionType} from "../../../Constants/actionType";
import {hasAdminAccess} from "../../../Utils/permissionUtils";
import {UserRoles} from "../../../Constants/userRoles";
export function MainInfoEditContent({ commonActions, form, actionType, user }) {
    const {t} = useTranslation();
    const isCustomerAdmin = hasAdminAccess(user, UserRoles.Customer);
    let data = form.data;

    const onChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }
    const onChangeContactPersonInfo = (field, value) => {
        onChange( 'contactPerson',{...data.contactPerson, [field]: value});
    }

    return <>
        <fieldset className="d-grid col-9 gap-16">
            <legend>{t("maininfocontainer.maininfocontactperson")}</legend>
            <FormInput label={t("maininfocontainer.firstname")} value={data.contactPerson?.firstName}
                       onChange={(input) => onChangeContactPersonInfo('firstName', input)}/>
            <FormInput label={t("maininfocontainer.lastname")} value={data.contactPerson?.lastName}
                       onChange={(input) => onChangeContactPersonInfo('lastName', input)}/>
            <FormInput label={t("maininfocontainer.emailaddress")} value={data.contactPerson?.email} type='email'
                       onChange={(input) => onChangeContactPersonInfo('email', input)}/>
            <FormInput label={t("maininfocontainer.phonenumber")} value={data.contactPerson?.phoneNumber} type='tel'
                       onChange={(input) => onChangeContactPersonInfo('phoneNumber', input)}/>
        </fieldset>
        <fieldset className="d-grid col-9 gap-16">
            <legend>{t("maininfocontainer.maininfocustomer")}</legend>
            {actionType === ActionType.Edit && <>
                <FormInput label={"Name"} value={data.name}
                               onChange={(input) => onChange('name', input)}/>
                <FormInput label={"Customer number"} value={data.customerNumber} readOnly={!isCustomerAdmin}
                                          onChange={(input) => onChange('customerNumber', input)}/>
                <FormInput label={"Org. no"} value={data.organizationNumber} readOnly={!isCustomerAdmin}
                           onChange={(input) => onChange('organizationNumber', input)}/>
            </>
            }
            <FormInput label={t("maininfocontainer.invoiceemail")} value={data.electronicInvoiceAddress} type='email'
                       onChange={(input) => onChange('electronicInvoiceAddress', input)}/>
            <FormInput label={t("maininfocontainer.streetaddress")} value={data.address1}
                       onChange={(input) => onChange('address1', input)}/>
            <FormInput label={t("maininfocontainer.postalcode")} value={data.postalCode} type='number'
                       onChange={(input) => onChange('postalCode', input)}/>
            <FormInput label={t("maininfocontainer.postalcity")} value={data.city}
                       onChange={(input) => onChange('city', input)}/>
            <FormInput label={t("maininfocontainer.coaddress")} value={data.address2}
                       onChange={(input) => onChange('address2', input)}/>
        </fieldset>
    </>
}