export const commonActionTypes = {
    SHOW_ALERT: "SHOW_ALERT",
    HIDE_ALERT: "HIDE_ALERT",
    LOADER_COMPONENT_VISIBILITY: "LOADER_COMPONENT_VISIBILITY",
    STORE_USER_INFO: "STORE_USER_INFO",
    USER_SEARCH_RESULTS: "USER_SEARCH_RESULTS",
    CUSTOMER_SEARCH_RESULTS: "CUSTOMER_SEARCH_RESULTS",
    LOAD_DROPDOWN_LIST: "LOAD_TYPESLIST",
    PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
    RESET_COMMON: "RESET_COMMON",
    FINISH_AUTHENTICATION: "FINISH_AUTHENTICATION",
    SHOW_MODAL: "SHOW_MODAL",
    SET_STARTUP_PARAMETERS: "SET_STARTUP_PARAMETERS",
    SET_EXTERNAL_URLS: "SET_EXTERNAL_URLS",
    SET_OPEN_HEADER: "SET_OPEN_HEADER",
    START_EDITING_FORM: "START_EDITING_FORM",
    CANCEL_EDITING_FORM: "CANCEL_EDITING_FORM",
    CHANGE_FORM_FIELD_VALUE: "CHANGE_FORM_FIELD_VALUE",
    STORE_FIELD_ERROR_FLAGS: "STORE_FIELD_ERROR_FLAGS",
    SHOW_VON_WARNING: "SHOW_VON_WARNING",
    SAVE_STATUS: "SAVE_STATUS",
    SORTED_LIST: "SORTED_LIST",
    STORE_TRANSACTION_SERVICES: "STORE_TRANSACTION_SERVICES",
    SHOW_COMPANY_EXPENSE_WARNING: "SHOW_COMPANY_EXPENSE_WARNING",
    RESELLER_SEARCH_RESULTS: "RESELLER_SEARCH_RESULTS",
    COMPANY_EXPENSE_PARTNER_SEARCH_RESULTS: "COMPANY_EXPENSE_PARTNER_SEARCH_RESULTS",
    STORE_SELECTED: "STORE_SELECTED",
    STORE_DEMO_PRODUCT: "STORE_DEMO_PRODUCT",
    STORE_USER_SHORTCUTS: "STORE_USER_SHORTCUTS",
    STORE_USER_SIGNATURE: "STORE_USER_SIGNATURE",
    STORE_DESKTOP_VERSION: "STORE_DESKTOP_VERSION",
    CHANGE_BUDGET_FORM_FIELD_VALUE: "CHANGE_BUDGET_FORM_FIELD_VALUE",
    STORE_TRANSACTION_SERVICES_TEAM: "STORE_TRANSACTION_SERVICES_TEAM",
    TOGGLE_PONG: "TOGGLE_PONG",
    STORE_NOTIFICATIONS: "STORE_NOTIFICATIONS",
    STORE_RELEASE_VERSION: "STORE_RELEASE_VERSION",
    STORE_RELEASE_VERSION_ALL: "STORE_RELEASE_VERSION_ALL",
    STORE_NOTIFICATIONS_COUNT: "STORE_NOTIFICATIONS_COUNT",
    STORE_MOVIE_MAILS: "STORE_MOVIE_MAILS",
    TOGGLE_SIDE_MENU: "TOGGLE_SIDE_MENU",
    SET_DATA_LOADING: "SET_DATA_LOADING",
}