import { bindActionCreators } from "redux";
import Card from "../../../../Components/Card/Card";
import { connect } from "react-redux";
import commonActions from "../../../../Actions/commonActions";
import customerActions from "../../../../Actions/customerActions";
import VonEmployeeCheck from "../../../../Components/Customer/VON/NewEmployee/VonEmployeeCheck";
import VonNewEmployeeForm from "../../../../Components/Customer/VON/NewEmployee/VonNewEmployeeForm";
import Loader from "../../../../Components/Loader";

function VonNewEmployeeFormContainer({ ...props }) {
    const { newVonEmployeeForm, isLoading } = props;

    return <Card>
        <Card.Header>
            <Card.Header.Title>Skapa användare</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            {!newVonEmployeeForm ? <VonEmployeeCheck {...props} /> : <VonNewEmployeeForm {...props} />}
            {isLoading && <div className="mb-24">
                <Loader/>
            </div>}
        </Card.Content>
    </Card>
}

const mapStateToProps = (state) => {
    return {
        newVonEmployeeForm: state.CommonReducer.forms && state.CommonReducer.forms.newVonEmployeeForm,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        isLoading: state.CommonReducer.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VonNewEmployeeFormContainer);