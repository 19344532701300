import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../Actions/customerActions'
import { withTranslation } from 'react-i18next';
import CompensationVbProducts from '../../Components/Customer/CompensationVbProducts'
import CompensationVonProducts from '../../Components/Customer/CompensationVonProducts'
import CompensationBlikkProducts from '../../Components/Customer/CompensationBlikkProducts'
import CompensationHistory from '../../Components/Customer/CompensationHistory'
import CompensationModal from '../../Components/Customer/CompensationModal'
import withModalForm from '../../Components/HOC/withModalForm'
import Panel from '../../Components/Panel';
import {DefaultCard} from "../../Components/Card/Card";

class CompensationContainer extends Component {

    componentDidMount() {
        this.props.customerActions.getCompensationsForCustomer(this.props.vbCustomer.customerNumber);
        this.props.customerActions.getBlikkProducts(this.props.vbCustomer.customerNumber);
    }

    renderNoData() {
        const {t} = this.props
        return (<p>{t('compensationcontainer.noentriesfound')}</p>);
    }

    onClick(item){
        alert(item.product)
    }

    render() {
        const { products, vonProducts} = this.props.vbCustomer;
        
        const { payload, isModalActive, onFieldChange, onCloseClick, onOpenModal, compensationForm, reasons, months, t, blikkProducts, blikkLoading } = this.props;

        const product = payload;

        const customerCompensations = this.props.customerCompensations;

        const AORestriction = this.props.vbCustomer.orderLimitationReason === "AOProduct"
        || this.props.vbCustomer.orderLimitationReason === "AOCentralAgreement";

        var compensationModalAttr = {
            form: compensationForm,
            reasons: reasons,
            months: months,
            onChange: onFieldChange,
            onClose: onCloseClick,           
            onLoad: () => this.props.customerActions.showCompensationModal(product, this.props.vbCustomer, AORestriction),
            onSave: (form, body) => this.props.customerActions.sendCompensation(product, this.props.vbCustomer.customerNumber, form, body, () => onCloseClick()),
            onMonthChange: (month) => this.props.customerActions.getNewCompensationPrice(product, month, this.props.vbCustomer.customerNumber),
            aoRestriction: AORestriction
        }

        return (
            <DefaultCard title={t('compensationcontainer.compensation')} isLoading={this.props.blikkLoading}>
                {(!products || products.length === 0 && !vonproducts || vonProducts === 0) ? this.renderNoData() : <>
                    <fieldset className="col-xs-12 vbProducts">
                        <div>
                            {products.length != 0 && <CompensationVbProducts products={products} onClick={onOpenModal} />}
                            {vonProducts.length != 0 && <CompensationVonProducts products={vonProducts} onClick={onOpenModal} />}
                            {blikkProducts && blikkProducts.length != 0 && <CompensationBlikkProducts products={blikkProducts} onClick={onOpenModal} />}
                            <CompensationHistory customerCompensations={customerCompensations} />
                            {isModalActive('compensation') && <CompensationModal {...compensationModalAttr} />}
                        </div>
                    </fieldset>
                </>}
            </DefaultCard>
        );
    }
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        compensationForm: state.CommonReducer.forms && state.CommonReducer.forms.compensation,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['CompensationReasons'],
        months: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['CompensationMonths'],
        customerCompensations: state.CustomerReducer.customerCompensations,
        blikkProducts: state.CustomerReducer.blikkProducts,
        blikkLoading: state.CustomerReducer.blikkLoading
    }
}

function mapDispatchToProps(dispatch) {
   return {
       customerActions: bindActionCreators(customerActions, dispatch)
    } 
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompensationContainer)));