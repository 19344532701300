export const targetGroups = {
    TestCustomer: { id: "524e8912-41ad-4a25-ab6d-ed1985181a2d"},
    AOSimplicity: { id: "265c85f0-1526-4aa3-8bd5-77819c1fd699"},
    NonInteractive: { id: "d409c1fe-08e8-4e40-a081-d46fef77257b" }
}

export const targetGroupConfigurations = {
    organizationNumberRequired: "organizationNumberRequired,",
    organizationNumberUnique: "organizationNumberUnique",
    customerNumberRequired: "customerNumberRequired",
    customerAddressRequired: "customerAddressRequired",
    contactPersonRequired: "contactPersonRequired",
    expiryDateRequired: "expiryDateRequired",
    excludeCustomerFromInvoiceFile: "excludeCustomerFromInvoiceFile"
}