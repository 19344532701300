import { customerActionTypes as actionTypes } from '../ActionTypes/customerActionTypes'
import { commonActionTypes } from '../ActionTypes/commonActionTypes'

export const initialState = {
    selectedCustomer: null,
    customer: null,
    vonAccounts: null,
    isValid: false,
    adminForm: null,
    selectedAdminForm: null,
    invoices: null,
    attendedEducations: null,
    educations: null,
    selectedEducation: null,
    educationFilters: null,
    desktopLicenses: null,
    collaborations: null,
    filteredCollaborations: null,
    saasTerminationRequests: null,
    compensationsResultsDropdown: null,
    aoCustomers: null,
    transactionServices: null,
    inyettData: null,
    connectedCustomers: null,
    vbProducts: null,
    subscriptions: null,
    thirdpartyproducts: null,
    dataLoading: {},
    relatedCustomers: null,
    customerInfoCounts: null,
    customerInfoFlags: null
}

export default function CustomerReducer(state = initialState, action) {

    let newState;

    switch (action.type) {
        case actionTypes.SHOW_CUSTOMER:
            newState = { selectedCustomer: action.customer?.oldVbCustomer, customer: action.customer }
            break;

        case actionTypes.SHOW_VON_ACCOUNTS:
            newState = { vonAccounts: action.data }
            break;

        case actionTypes.SET_VON_ACCOUNT_SEARCH_INPUT:
            newState = { searchVonAccountInput: action.vonAccountInput }
            break;

        case actionTypes.CUSTOMER_DATA_STATUS:
            newState = { isValid: action.isValid }
            break;
        case actionTypes.UPDATE_CUSTOMER_APPOINTMENTS_COUNT:
            newState = { openAppointmentsCount: action.openAppointments };
            break;

        case actionTypes.UPDATE_CUSTOMER_LEADS_COUNT:
            newState = { openLeadsCount: action.openLeadsCount };
            break;

        case actionTypes.UPDATE_CUSTOMER_AOLEADS_COUNT:
            newState = { openAOLeadsCount: action.openAOLeadsCount };
            break;

        case actionTypes.RESET:
            newState = initialState
            break;

        case actionTypes.SELECT_ADMIN_FORM:
            newState = { selectedAdminForm: action.selectedAdminForm }
            break;

        case actionTypes.STORE_INVOICES:
            newState = { invoices: action.data }
            break;

        case actionTypes.STORE_ATTENDED_EDUCATIONS:
            newState = { attendedEducations: action.data }
            break;

        case actionTypes.STORE_ATTENDED_ONBOARDINGS:
            newState = { attendedOnboardings: action.data }
            break;

        case actionTypes.STORE_EDUCATIONS:
            newState = { educations: action.data.educations, educationFilters: { locations: action.data.locations, subjects: action.data.subjects } }
            break;

        case actionTypes.STORE_EDUCATION:
            newState = { selectedEducation: action.data }
            break;

        case actionTypes.STORE_COURSE:
            newState = { selectedCourse: action.data }
            break;

        case actionTypes.GET_DESKTOP_LICENSES:
            newState = { desktopLicenses: action.data }
            break;

        case actionTypes.STORE_COLLABORATIONS:
            newState = { collaborations: action.data.collaborations, filteredCollaborations: action.data.collaborations, collaborationsGrouped: action.data.grouped }
            break;
        case actionTypes.CLEAR_COLLABORATIONS:
            newState = { collaborations: action.data, filteredCollaborations: action.data, collaborationsGrouped: action.data }
            break;

        case commonActionTypes.SORTED_LIST:
            if (action.listName === "collaborations")
                newState = { collaborations: action.list }
            if (action.listName === "filteredCollaborations") {
                newState = { filteredCollaborations: action.list }
            }
            if (action.listName === "aoCustomers")
                newState = { aoCustomers: action.list }
            break;

        case actionTypes.CLEAR_AO_CUSTOMERS:
            newState = { aoCustomers: action.data }
            break;

        case actionTypes.STORE_CUSTOMER_DISCOUNTS:
            newState = { customerDiscounts: action.data }
            break;
        case actionTypes.STORE_VON_DISCOUNTS:
            newState = { vonDiscounts: action.data }
            break;

        case actionTypes.STORE_PENDING_TERMINATION_REQUESTS:
            newState = { pendingTerminationRequests: action.data }
            break;

        case actionTypes.STORE_RELATED_CUSTOMERS:
            newState = { relatedCustomers: action.data }
            break;

        case actionTypes.GET_WEBINARS_FOR_SALES_PERSON:
            newState = { webinars: action.data.webinars, webinarFilters: { products: action.data.products } }
            break;

        case actionTypes.SET_SELECTED_WEBINAR:
            newState = { selectedWebinar: action.data }
            break;

        case actionTypes.STORE_BOKAMERA_SERVICES:
            newState = { bokaMeraServices: action.data }
            break;

        case actionTypes.STORE_BOKAMERA_TIMES:
            newState = { bokaMeraTimes: action.data, bokaMeraServiceId: action.bokaMeraServiceId, bokaMeraWeekNo: action.bokaMeraWeekNo }
            break;

        case actionTypes.CLEAR_BOKAMERA_TIMES:
            newState = { bokaMeraTimes: action.data, bokaMeraServiceId: action.bokaMeraServiceId, bokaMeraWeekNo: action.bokaMeraWeekNo }
            break;
        case actionTypes.SET_VCE_EMAIL_TEMPLATE:
            newState = { vceEmailTemplate: action.data }
            break;
        case actionTypes.SET_BOKA_MERA_MODAL_OBJECT:
            newState = { bokaMeraFromDate: action.fromDate, bokaMeraToDate: action.toDate, bokaMeraService: action.service, availableResources: action.availableResources }
            break;

        case actionTypes.STORE_BOKAMERA_CUSTOMFIELDS:
            // const list = action.data.map(item => { return {id: item.id, name: item.name, value: ""} })
            newState = { bokaMeraCustomFields: action.data }
            break;

        case actionTypes.STORE_EMPLOYEES:
            newState = { employees: action.data }
            break;

        case actionTypes.CLEAR_EMPLOYEES:
            newState = { employees: null }
            break;

        case actionTypes.CLEAR_CUSTOM_FIELDS:
            const list = action.data.map(item => {
                item.value = ""
                return (item)
            });
            newState = { bokaMeraCustomFields: list }
            break;

        case actionTypes.SET_INTERNAL_LEAD_EMAIL_TEMPLATE:
            newState = { internalLeadEmailTemplate: action.data }
            break;

        case actionTypes.GET_COMPENSATIONS_FOR_CUSTOMER:
            newState = { customerCompensations: action.data }
            break;
        case actionTypes.GET_COMPENSATIONS_RESULTS_DROPDOWN:
            newState = { compensationsResultsDropdown: action.data }
            break;

        case actionTypes.UPDATE_CUSTOMER_COMPENSATION_HISTORY_COUNT:
            newState = { compensationHistoryCount: action.compensationHistoryCount }
            break;

        case actionTypes.STORE_CAMPAIGNS_FOR_CUSTOMER:
            newState = { customerCampaigns: action.data }
            break;

        case actionTypes.STORE_VISMA_FINANCE_PRODUCTS:
            newState = {
                vismaFinance: {
                    vismaFinanceResponse: action.data.response,
                    vismaFinanceProducts: action.data.products,
                    vismaFinanceCustomerNumber: action.data.vismaFinanceCustomerNumber
                }
            }
            break;

        case actionTypes.SET_VISMA_FINANCE_PRODUCTS_LOADING:
            newState = { vismaFinanceProductLoading: action.data }
            break;

        case actionTypes.STORE_COMPANY_EXPENSE_PRODUCTS:
            newState = { companyExpenseResponse: action.data.response, companyExpenseProducts: action.data.products, companyExpenseCustomerNumber: action.data.companyExpenseCustomerNumber }
            break;
        case actionTypes.SET_COMPANY_EXPENSE_PRODUCTS_LOADING:
            newState = { companyExpenseProductLoading: action.data }
            break;

        case actionTypes.STORE_BLIKK_PRODUCTS:
            newState = { blikkResponse: action.data.response, blikkProducts: action.data.products, blikkCustomerNumber: action.data.blikkCustomerNumber }
            break;
        case actionTypes.GET_AO_CUSTOMER_INFO:
            newState = { aoCustomerInfo: action.data }
            break;
        case actionTypes.STORE_AO_CUSTOMERS:
            newState = { aoCustomers: action.data }
            break;
        case actionTypes.STORE_SENT_MOVIES:
            newState = { sentMovies: action.data }
            break;
        case actionTypes.STORE_SUBSCRIPTIONS:
            newState = { subscriptions: action.data, selectedCustomer: { ...state.selectedCustomer, subscriptions: action.data} }
            break;
        case actionTypes.STORE_VBPRODUCTS:
            newState = { vbProducts: action.data.vbProducts, selectedCustomer: { ...state.selectedCustomer, vbProducts: action.data.vbProducts, products: action.data.products } }
            break;
        case actionTypes.STORE_VONPRODUCTS:
            newState = { vonProducts: action.data, selectedCustomer: { ...state.selectedCustomer, vonProducts: action.data} }
            break;
        case actionTypes.STORE_CONNECTED_CUSTOMERS:
            newState = { connectedCustomers: action.data, selectedCustomer: { ...state.selectedCustomer,
                    mainLonSmartCustomer: action.data.connectedLonSmartCustomers.mainCustomers,
                    subLonSmartCustomers: action.data.connectedLonSmartCustomers.subCustomers,
                    mainSkattBokslutCustomer: action.data.connectedSkattBokslutCustomers.mainCustomers,
                    subSkattBokslutCustomers: action.data.connectedSkattBokslutCustomers.subCustomers,
                    mainCustomer: action.data.mainCustomer,
                    subCustomers: action.data.subCustomers
            }}
            break;
        case actionTypes.STORE_TRANSACTION_SERVICES:
            newState = { transactionServices: action.data }
            break
        case actionTypes.STORE_INYETT_DATA:
            newState = { inyettData: action.data }
            break
        case actionTypes.STORE_CUSTOMER_INFO_COUNTS:
            newState = { customerInfoCounts: action.data }
            break
        case actionTypes.STORE_CUSTOMER_INFO_FLAGS:
            newState = { customerInfoFlags: action.data }
            break
        default:
            return state
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}