import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {Routes, Route, useParams, useNavigate } from 'react-router-dom'
import './Customer/CustomerContainer.css'
import CustomerEditContainer from './Customer/CustomerEditContainer'
import EducationsContainer from './Customer/EducationsContainer'
import VonAccountsContainer from './Customer/VonAccountsContainer'
import AppointmentsContainer from './Customer/AppointmentsContainer'
import VonAccountFormContainer from './Customer/VonAccountFormContainer'
import CustomerAppointmentFormContainer from './Customer/CustomerAppointmentFormContainer'
import AdminFormsContainer from './Customer/AdminFormsContainer'
import InvoicesContainer from './Customer/InvoicesContainer'
import ActionLogContainer from './Customer/ActionLogContainer'
import AttendedEducationsNavigationContainer from './Customer/AttendedEducationsNavigationContainer'
import ConsultingContainer from './Customer/ConsultingContainer'
import SendDesktopVersionContainer from './Customer/SendDesktopVersionContainer'
import CollaborationsContainer from './Customer/CollaborationsContainer'
import DiscountsContainer from './Customer/DiscountsContainer'
import CustomerLeadsContainer from './Customer/CustomerLeadsContainer'
import RelatedCustomersContainer from './Customer/RelatedCustomersContainer'
import CompensationContainer from './Customer/CompensationContainer'
import CampaignContainer from './Customer/CampaignContainer'
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import customerActions from '../Actions/customerActions'
import quotationActions from '../Actions/quotationActions'
import commonActions from '../Actions/commonActions'
import CourseInfoContainer from './Customer/CourseInfoContainer';
import OrderContainer from '../Containers/OrderContainer'
import OrderPlacementContainer from './OrderPlacement/OrderPlacementContainer';
import OrderConfirmationContainer from './Order/OrderConfirmationContainer';
import { UserRoles } from "../Constants/userRoles";
import RequireAuth from "../Components/Permission/RequireAuth";
import BookEducationContainer from './Customer/BookEducationContainer';
import MainContainer from "./Customer/MainContainer";
import CustomerTopMenu from "../Components/Customer/CustomerTopMenu";
import { FeatureFlags, isFeatureEnabled } from "../Utils/featureFlags";
import ActivityLogContainer from './Settings/InternalCustomerAdmin/ActivityLog/ActivityLogContainer';
import SubscriptionsDiscountContainer from './Customer/VOSS/Discounts/SubscriptionsDiscountContainer';
import VonNewEmployeeFormContainer from './Customer/VON/Employee/VonNewEmployeeFormContainer';
import VonEditEmployeeFormContainer from './Customer/VON/Employee/VonEditEmployeeFormContainer';
import VonAdministerLicenseFormContainer from './Customer/VON/Licenses/VonAdministerLicenseFormContainer';
import helpers from "../Utils/helpers";
import InyettContainer from "./Customer/InyettContainer";

function CustomerContainer({ commonActions, customerActions, vbCustomer, customer, user, isProduction, dataLoading }) {
    const navigate = useNavigate();

    const { customerNumber } = useParams();

    useEffect(() => {
        runWootric()

        return () => {
            customerActions.reset();
            commonActions.clearStoreSelected();
        }
    }, [])

    const navigateToMain = (id) => {
        navigate(location.pathname.replace(customerNumber,id));
    }

    const getCustomer = () => {
        if ((!customer || (vbCustomer?.customerNumber !== customerNumber && customer?.pathId !== customerNumber)) && !dataLoading) {
            customerActions.getSelectedCustomer(customerNumber, navigateToMain);
        }
    }

    useEffect(() => {
        getCustomer();
    }, [customerNumber, dataLoading]);

    useEffect(() => {
        getCustomer();
    }, []);

    const runWootric = () => {
        if (!window.wootric) return;

        //window.wootric_survey_immediately = true; // Shows survey immediately for testing purposes. TODO: Comment out for production.
        window.wootricSettings = {
            email: user.email,
            // external_id: 'abc123', // TODO: Reference field for external integrations only. Send it along with email. OPTIONAL
            //created_at: // TODO: The current logged in user's sign-up date as a 10 digit Unix timestamp in seconds. OPTIONAL
            properties: {
                departmentId: user.departmentId,
                productName: "Cockpit",
                enviroment: isProduction ? "Production" : "Test"
            },
            account_token: 'NPS-EU-8313bbed' // This is your unique account token.
            //account_token: 'NPS-e677e1bb' // Old token for US account.
        }
        window.wootric('run');
    }

    if (!customer) {
        return null;
    }
    // /details/ path should be removed when all references are updated
    return (
        <I18nextProvider i18n={i18n}>
            <CustomerTopMenu />
            <Routes>
                <Route path={'/main/'} element={<MainContainer />} />
                <Route path={'/details/'} element={<MainContainer />} />
                <Route path={'/edit/'} element={<CustomerEditContainer />} />
                <Route path={'/agreements/'} element={<MainContainer customerInfoOption="agreements" />} />
                <Route path={'/von-accounts/'} exact element={<VonAccountsContainer />} />
                <Route path={'/von-accounts/new'} element={<VonAccountFormContainer />} />
                <Route path={'/von-accounts/new-employee'} element={<VonNewEmployeeFormContainer />} />
                <Route path={'/von-accounts/edit-employee/:id'} element={<VonEditEmployeeFormContainer />} />
                <Route path={'/von-accounts/administer-licenses'} element={<VonAdministerLicenseFormContainer />} />
                <Route path={'/collaborations/'} exact element={<CollaborationsContainer />} />
                <Route path={'/log/'} element={<ActivityLogContainer customer={customer} />} />
                <Route path={'/discounts/'} exact element={<DiscountsContainer />} />
                <Route path={'/voss/discounts/'} element={<SubscriptionsDiscountContainer />} />
                <Route path={'/appointments/'} element={<AppointmentsContainer />} />
                <Route path="/appointment/:appointmentId/" element={<CustomerAppointmentFormContainer />} />
                <Route path={'/admin-forms/'} element={<AdminFormsContainer />} />
                <Route path={'/new-appointment/'} element={<CustomerAppointmentFormContainer />} />
                <Route exact path={'/educations/*'} element={<EducationsContainer />} />
                <Route path={'/bookeducation/:courseId/'} element={<BookEducationContainer />} />
                <Route path={'/educations/course/:courseId/:eventId/'} element={<CourseInfoContainer />} />
                <Route path="/orderconfirmationmail/:orderId" element={<OrderConfirmationContainer />} />
                <Route path={'/invoices/'} element={<InvoicesContainer />} />
                <Route path={'/customeractions/'} element={<ActionLogContainer />} />
                <Route path={'/attended-educations/*'} element={<AttendedEducationsNavigationContainer />} />
                <Route path={'/consulting/'} element={<ConsultingContainer />} />
                <Route path={'/send-desktop-version/'} element={<SendDesktopVersionContainer />} />
                <Route path={'/leads/*'} element={<CustomerLeadsContainer />} />
                <Route path={'/related-customers/'} element={<RelatedCustomersContainer />} />
                <Route path={'/compensation/'} element={<CompensationContainer />} />
                <Route path={'/campaigns/'} element={<CampaignContainer />} />
                <Route element={<RequireAuth.RequireReadWriteAuth roles={[UserRoles.SpcsSales]} />}>
                    <Route path={'/order/'} element={<OrderContainer />} />
                    <Route path={'/order-placement/'} element={<OrderPlacementContainer />} />
                </Route>

                <Route path={'/inyettdetails/'} element={<InyettContainer/>} />
            </Routes>
            {/*<Outlet/>*/}
        </I18nextProvider>
    );
}

function mapStateToProps(state) {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        isProduction: state.CommonReducer.isProduction,
        dataLoading: state.CommonReducer.dataLoading.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        quotationActions: bindActionCreators(quotationActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);