import { bindActionCreators } from "redux";
import Card from "../../../../Components/Card/Card";
import { connect } from "react-redux";
import customerActions from "../../../../Actions/customerActions";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VonEditEmployeeDetailsForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeDetailsForm";
import VonEditAccessRightsForm from "../../../../Components/Customer/VON/EditEmployee/VonEditAccessRightsForm";
import VonEditEmployeeTypeForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeTypeForm";
import routes from "../../../../Constants/routes";
import commonActions from "../../../../Actions/commonActions";
import { VonAccessRights } from "../../../../Constants/VON/EmployeeForm/vonAccessRights";
import Loader from "../../../../Components/Loader";
import VonEditEmployeeRolesForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeRolesForm";
import AlertSmall from "../../../../Components/AlertSmall";

function VonEditEmployeeFormContainer({ ...props }) {
    const { customerActions, commonActions, editVonEmployeeForm, editVonEmployeeRolesForm, isLoading, vbCustomer } = props;
    const { id } = useParams();
    const customerNumber = vbCustomer.customerNumber;
    const navigate = useNavigate();

    useEffect(() => {
        customerActions.getVonAccounts(customerNumber);
        customerActions.getVonEmployeeForEdit(customerNumber, vbCustomer.vonProducts, id); //Nellie vonProducts?

        return () => {
            commonActions.cancelFormEditing('editVonEmployeeForm');
            commonActions.cancelFormEditing('editVonEmployeeRolesForm');
        }
    }, [])

    const onChange = (form, value, field) => {
        commonActions.changeMultiFormField(form, field, value);
    }

    const onSubmit = () => {
        switch (editVonEmployeeForm.data.onlineRoleId) {
            case VonAccessRights.Delete:
                customerActions.deleteVonEmployee(id, () => navigate(routes.customerVonAccounts(customer.pathId)));
                break;
            case VonAccessRights.Inactive:
                customerActions.inactivateVonEmployee(id, () => navigate(routes.customerVonAccounts(customer.pathId)));
                break;
            default:
                customerActions.updateVonEmployee(editVonEmployeeForm, editVonEmployeeRolesForm, () => navigate(routes.customerVonAccounts(customer.pathId)));
                break;
        }
    }

    const isPrimaryContact = editVonEmployeeForm?.data?.isPrimaryContact;

    return <Card>
        <Card.Header>
            <Card.Header.Title>Editera användare</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            {!isLoading ?
                <div className="d-grid gap-24">
                    <div className="d-flex">
                        <fieldset className="d-grid col gap-24 mb-0 pl-0">
                            {editVonEmployeeForm && <>
                                <VonEditEmployeeDetailsForm data={editVonEmployeeForm.data} {...props} onChange={onChange} />
                                <VonEditEmployeeTypeForm data={editVonEmployeeForm.data} {...props} onChange={onChange} />
                                <VonEditAccessRightsForm data={editVonEmployeeForm.data} {...props} onChange={onChange} />
                            </>}
                            {isPrimaryContact && <div>
                                <AlertSmall alert={{type:"warning", title: "Användaruppgifter kan inte editeras!", message: "Du måste först markera en annan användare som huvudkontakt"}} />
                                </div>}
                        </fieldset>
                        <fieldset className="d-grid col gap-24 mb-0 pl-0">
                            {editVonEmployeeRolesForm && <>
                                <VonEditEmployeeRolesForm data={editVonEmployeeRolesForm.data} {...props} onChange={onChange} />
                            </>}
                        </fieldset>
                    </div>
                    <div>
                        <div className="float-right">
                            <button className='btn btn-default' onClick={() => onSubmit()}>Spara</button>
                            <button className='btn btn-default' onClick={() => navigate(routes.customerVonAccounts(customer.pathId))} >Avbryt</button>
                        </div>
                    </div>
                </div> : <div className="mb-24"><Loader /></div>}
        </Card.Content>
    </Card>
}

const mapStateToProps = (state) => {
    return {
        editVonEmployeeForm: state.CommonReducer.forms && state.CommonReducer.forms.editVonEmployeeForm,
        editVonEmployeeRolesForm: state.CommonReducer.forms && state.CommonReducer.forms.editVonEmployeeRolesForm,
        vonAccounts: state.CustomerReducer.vonAccounts,
        isLoading: state.CommonReducer.isLoading,
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VonEditEmployeeFormContainer);