import {useTranslation, withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Modal from "../../Modal";
import {MainInfoEditContent} from "./MainInfoEditContent";
import {InternalInfoEditContent} from "./InternalInfoEditContent";
import {ActionType} from "../../../Constants/actionType";
import {bindActionCreators} from "redux";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import {connect} from "react-redux";

function EditCustomerModal ({form, onCloseClick, customer, commonActions, customerActions, dropdownlists, actionType, user }) {
    const {t} = useTranslation();

    useEffect(() => {
        commonActions.startFormEditing("vossCustomerFormData", customer.vossCustomer)

        return () => {
            commonActions.cancelFormEditing("vossCustomerFormData");
        }
    }, []);

    const modalProps = {
        title: `Edit customer: ${customer.vossCustomer?.name ?? customer.vbCustomer?.name}`,
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => onCloseClick(form.id),
        onSaveClick: () => customerActions.saveVossCustomer(form, ActionType.Edit, onCloseClick),
        mainButtonTitle: t("common.save"),
        customClassName: "modal-xl"
    }

    return form && form.data && <Modal {...modalProps}>
        <form className="skynet-form container">
            <div className="row">
                <div className="col col-6">
                    <MainInfoEditContent commonActions={commonActions}
                                         form={form} actionType={actionType} user={user}/>
                </div>
                <div className="col col-6">
                    <InternalInfoEditContent dropdownlists={dropdownlists}
                                             commonActions={commonActions} form={form} actionType={actionType}/>
                </div>
            </div>
        </form></Modal>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        user: state.CommonReducer.user,
        form: state.CommonReducer.forms && state.CommonReducer.forms.vossCustomerFormData,
        dropdownlists: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditCustomerModal));
