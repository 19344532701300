import { bindActionCreators } from "redux";
import Card from "../../../../Components/Card/Card";
import { connect } from "react-redux";
import commonActions from "../../../../Actions/commonActions";
import customerActions from "../../../../Actions/customerActions";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { VonServiceSelection } from "../../../../Components/Customer/VON/AdministerLicenses/VonServiceSelection";
import VonEditEmployeeLicensesForm from "../../../../Components/Customer/VON/AdministerLicenses/VonEditEmployeeLicensesForm";
import routes from "../../../../Constants/routes";
import Loader from "../../../../Components/Loader";

function VonAdministerLicenseFormContainer({ ...props }) {
    const { customerActions, vonLicenseAdministrationForm, vonAccounts, vbCustomer, commonActions, isLoading, customer } = props;
    const customerNumber = vbCustomer.customerNumber;
    const services = vbCustomer.vonProducts.filter(product => product.licenses > 0);
    const [selectedService, setSelectedService] = useState(services[0]);
    const navigate = useNavigate();

    useEffect(() => {
        customerActions.getVonAccounts(customerNumber);

        return () => {
            commonActions.cancelFormEditing("vonLicenseAdministrationForm");
        }
    }, [])

    useEffect(() => {
        if (vonAccounts)
            customerActions.beginVonLicenseAdministrationForm(vonAccounts, selectedService);
    }, [vonAccounts])

    const onChangeService = (service) => {
        customerActions.beginVonLicenseAdministrationForm(vonAccounts, service);
        setSelectedService(service);
    }

    const onChange = (form, value, field) => {
        commonActions.changeMultiFormField(form, field, value);
    }

    const onSubmit = () => {
        customerActions.bulkUpdateVonEmployeeServices(vonLicenseAdministrationForm, () => { customerActions.getVonAccounts(customerNumber) });
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>Administrera licenser</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            {!isLoading ? <div className="d-grid gap-24">
                <fieldset className="d-grid gap-24 mb-0 pl-0">
                    <VonServiceSelection selectedService={selectedService.productNumber} onChange={onChangeService} services={services} {...props} />
                    {vonLicenseAdministrationForm && <VonEditEmployeeLicensesForm onChange={onChange} selectedService={selectedService} {...props} />}
                </fieldset>
                <div>
                    <div className="float-right">
                        <button className="btn btn-default" onClick={onSubmit}>Spara</button>
                        <button className="btn btn-default" onClick={() => navigate(routes.customerVonAccounts(customer.pathId))}>Tillbaka</button>
                    </div>
                </div>
            </div> : <div className="mb-24"> <Loader override /></div>
            }
        </Card.Content>
    </Card>
}

const mapStateToProps = (state) => {
    return {
        vbCustomer: state.CustomerReducer.selectedCustomer,
        customer: state.CustomerReducer.customer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        vonLicenseAdministrationForm: state.CommonReducer.forms && state.CommonReducer.forms.vonLicenseAdministrationForm,
        isLoading: state.CommonReducer.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VonAdministerLicenseFormContainer);