import { useTranslation } from "react-i18next";
import { FormInput} from '../../FormComponents/Input/Input';
export function MainInfo({ customer }) {
    const {t} = useTranslation();

    return <fieldset className="d-grid mb-0 grid-cols-5 gap-24">
            <FormInput label={t("maininfocontainer.customernumber")} value={customer.customerNumber} readOnly={true}/>
            <FormInput label={t("maininfocontainer.contactperson")} value={customer.contactPerson.firstName} readOnly={true}/>
            <FormInput label={t("maininfocontainer.invoiceemail")} value={customer.electronicInvoiceAddress ?? customer.invoiceEmail} type='email' readOnly={true}/>
            <FormInput label={t("maininfocontainer.streetaddress")} value={customer.address1} readOnly={true}/>
            <FormInput label={t("maininfocontainer.postalcode")} value={customer.postalCode} type='number' readOnly={true}/>
            <FormInput label={t("maininfocontainer.organizationnumber")} value={customer.organizationNumber} readOnly={true}/>
            <FormInput label={t("maininfocontainer.emailaddress")} value={customer.email} type='email' readOnly={true}/>
            <FormInput label={t("maininfocontainer.coaddress")} value={customer.address2} readOnly={true}/>
            <FormInput label={t("maininfocontainer.postalcity")} value={customer.city} readOnly={true}/>
            <FormInput label={t("maininfocontainer.phonenumber")} value={customer.contactPerson?.phoneNumber} type='tel' icon="vismaicon-phone icon-neutral" readOnly={true}/>
        </fieldset>
}