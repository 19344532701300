import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Dropdown} from "../../FormComponents/Dropdown"
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import {FormInput} from "../../FormComponents/Input/Input";
import {ActionType} from "../../../Constants/actionType";
import CustomerSearchNew from "../../Search/CustomerSearchNew";
import FormSearch from "../../Search/FormSearch";
import UserSearchNew from "../../Search/UserSearchNew";

export function InternalInfoEditContent({ dropdownlists, commonActions, form, actionType }) {
    const { t } = useTranslation();

    const invoiceFees = [
        { id: 8192, name: "Yes" },
        { id: 8200, name: "No" }
    ];
    const paymentTerms = [
        { id: 30, name: "30 dagar netto" },
        { id: 2, name: "2 postförskott" }]

    const onChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value);
    }
    const onDeleteTargetGroup = (index) => {
        commonActions.changeFormArrayField(form.id, "targetGroups", form.data?.targetGroups, index, '-1');
    }
    const onAddTargetGroup = (value) => {

        form.data.targetGroups = form.data.targetGroups ?? [];

        let name = dropdownlists?.TargetGroup.find(x => x.guid === value)?.name;
        const newTargetGroup = { name: name, id: value};

        commonActions.changeFormArrayField(form.id, "targetGroups", form.data.targetGroups, form.data.targetGroups?.length, newTargetGroup);
    }

    return (<>
        {actionType === ActionType.Edit &&
            <fieldset className="d-grid col-9 gap-16">
                <legend>{t("internalinfocontainer.header")}</legend>
                <FormSearch label={"Invoice customer number"}>{(className) => <CustomerSearchNew onChange={(value) => onChange('invoiceCustomerNumber', value)} className={className + "pb-0"} />}</FormSearch>
                <FormInput label={"credit limit"} value={form.data?.creditLimit} type='tel'
                           onChange={(value) => onChange('creditLimit', value)}/>
                <Dropdown label={"Invoice fe"} options={invoiceFees}
                          initialValue={form.data?.invoiceFee}
                          value={form.data?.invoiceFee}
                          placeholder={"-- Select invoice fee --"}
                          onChange={(value) => onChange('invoiceFee', value)}/>
                <Dropdown label={"spcsEdi"} options={dropdownlists?.SpcsEdi}
                          initialValue={form.data?.spcsEdi}
                          value={form.data?.spcsEdi}
                          placeholder={"-- Select spcsEdi --"}
                          onChange={(value) => onChange('spcsEdi', value)}/>
                <Dropdown label={"paymentTerms"} options={paymentTerms}
                          initialValue={form.data?.paymentTerms}
                          value={form.data?.paymentTerms}
                          placeholder={"-- Select payment terms --"}
                          onChange={(value) => onChange('paymentTerms', value)}/>
            </fieldset>
        }
            <fieldset className="d-grid col-9 gap-16">
            <legend>{t("internalinfocontainer.header")}</legend>
                {actionType === ActionType.Edit && <>
                    <FormSearch label={"Responsible person"}>{(className) => <UserSearchNew onChange={(value) => onChange('responsiblePerson', value)} defaultSelected={{ name: form.data?.responsibleSalesPersonName || "", email: form.data?.responsibleSalesPersonEmail }} label={t('internalinfocontainer.responsibleperson')} className={"form-control rounded-8 skynet-form-input"}/>}</FormSearch>
                    <Dropdown label={"Status bre"} options={dropdownlists?.CustomerStatus}
                              value={form.data?.status} initialValue={form.data?.status}
                                                   placeholder={"Välj status bre"}
                                                   onChange={(value) => onChange('status', value)}/>
                </>
                }
            <Dropdown label={t("internalinfocontainer.customergroup")} options={dropdownlists?.CustomerGroup}
                      placeholder={t("internalinfocontainer.selectcustomergroup")}
                      initialValue={form.data?.customerGroup}
                      value={form.data?.customerGroup}
                      onChange={(value) => onChange('group', value)}/>
                {/*
            {actionType === ActionType.Create && <>
                <Dropdown label={"Customer group 2"} options={dropdownlists?.CustomerGroup2}
                          placeholder={t("internalinfocontainer.selectcustomergroup")}
                          value={form.data?.group2}
                          initialValue={form.data?.group2}
                          onChange={(value) => onChange('customerGroup2', value)}/>
            </>
            }*/}
            {/*Target groups in voss maps von type
            <Dropdown label={t("internalinfocontainer.customertype")} options={dropdownlists?.CustomerType}
                      value={form.data?.type} initialValue={form.data?.type}
                      placeholder={t("internalinfocontainer.selectcustomertype")} onChange={(value) => onChange('customerType', value)}/>*/}
            <div>
            <label>{t("internalinfocontainer.targetgroup")}</label>
                <table className="table table-stripe skynet-table-no-divider">
                    <tbody>
                    {form.data?.targetGroups?.map((targetGroup, index) => (
                        <tr key={index}>
                            <td>{targetGroup.name}</td>
                            <td>
                                <div className="float-right">
                                    <SkynetIcon icon={'vismaicon-delete'} onClick={() => onDeleteTargetGroup(index)}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td className="p-12" colSpan="2">
                            <Dropdown options={dropdownlists?.TargetGroup} valueProperty={"guid"}
                                      property={"name"} placeholder={t("internalinfocontainer.selecttargetgroup")}
                                      onChange={(value) => onAddTargetGroup(value)}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
        </fieldset>
        </>
    )
}