import Card from "../../../Card/Card";
import QuantitySelector from "../../../FormComponents/QuantitySelector";
import { VossProductType } from "../../../../Constants/VOSS/vossProductType";
import { VossProductGroupType } from "../../../../Constants/VOSS/vossProductGroupType";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function VossLicenseOptionsCard({ ...props }) {
    const { t } = useTranslation();
    return <Card>
        <Card.Header>
            <Card.Header.Title>{t('vosslicenseoptions.title')}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VossLicenseOptions {...props} />
        </Card.Content>
    </Card>
}

function VossLicenseOptions({ ...props }) {
    const { product, onChange } = props;

    let tier = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId);
    let licenseProducts = tier?.productGroups.flatMap(group => {
        if (group.productGroupType === VossProductGroupType.Included || product.priceCalculation.optionalProductGroups?.some(optGroup => optGroup.id === group.id)) {
            return group.products;
        }
        return [];
    }).filter(product => product.type === VossProductType.License) || [];

    const onChangeLicense = (newValue, productId) => {
        let updatedLicenses = product.priceCalculation.licenses.map(license =>
            license.productId === productId
                ? { ...license, quantity: newValue }
                : license
        );
        onChange("licenses", updatedLicenses);
    };

    const getProductLicenses = (productId) => {
        return product.priceCalculation.licenses.find(license => license.productId === productId);
    };

    return <fieldset className="d-grid col gap-16 mb-0 px-0">
            {licenseProducts.map(product => (
                <LicenseSelector
                    product={getProductLicenses(product.productId)}
                    onChange={onChangeLicense}
                    title={product.name}
                />
            ))}
        </fieldset>
}

function LicenseSelector({ onChange, title, product }) {
    return <div key={product.productId}>
        <h4 className="bold">{title}</h4>
        <QuantitySelector
            lowerThreshold={product.initialQuantity}
            value={product.quantity}
            onChange={(value) => onChange(value, product.productId)}
        />
    </div>
}