import { VonEmployeeTypes } from "../../../../Constants/VON/EmployeeForm/vonEmployeeTypes";
import { Dropdown } from "../../../FormComponents/Dropdown";

export default function VonEditEmployeeRolesForm({ data, vbCustomer, onChange, vonAccounts }) {

    const createDropdownOptions = (serviceRoles) => {
        switch (data.employeeTypeId) {
            case VonEmployeeTypes.Auditor.id:
                serviceRoles = serviceRoles.filter(role => role.name.endsWith(':auditor'));
                break;
            default:
                serviceRoles = serviceRoles.filter(role => !role.name.endsWith(':auditor'));
                break;
        }
        return [
            { id: '0', displayName: 'Ej tillgång' },
            ...serviceRoles
        ];
    };

    const getService = (product) => {
        const { services } = data;
        const { productNumber } = product;

        const serviceIndex = services.findIndex(s => s.serviceId === productNumber);
        return services[serviceIndex];
    };

    const getValue = (product) => {
        const service = getService(product);
        if (service) {
            return service.roleId;
        }
    };

    const onSelectRole = (value, product) => {
        const { productNumber } = product;
        const service = getService(product);

        if (service && service.removeService != undefined) {
            service.roleId = value;
            service.removeService = (value === '0');
        } else if (value === '0') {
            data.services = data.services.filter(s => s.serviceId !== productNumber);
        } else {
            data.services.push({ serviceId: productNumber, roleId: value });
        }

        onChange('editVonEmployeeRolesForm', data.services, 'services');
    };

    const getActiveUsers = (product) => {
        let activeUsers = 0;
        vonAccounts?.forEach(account => {
            if (account.roles.find(role => role.productName === product.product)) {
                activeUsers++;
            }
        });

        const service = getService(product);
        if (service) {
            switch (service.removeService) {
                case true:
                    activeUsers--;
                    break;
                case undefined:
                    activeUsers++;
                    break;
            }
        }
        return activeUsers;
    };

    const disabled = (product) => {
        const service = getService(product);
        if (service && (service.removeService === undefined || service.removeService === false)) {
            return false;
        }
        return product.licenses !== 0 && getActiveUsers(product) >= product.licenses;
    }

    return (
        <div>
            <fieldset className="d-grid col gap-16 mb-0">
                <legend>Tillgång till tjänsterna</legend>
                {vbCustomer.vonProducts.map((product, index) => {
                    return <div className="d-flex align-items-end">
                        <div className="col-6">
                            <Dropdown
                                initialValue={0}
                                key={index}
                                disabled={disabled(product)}
                                label={product.product}
                                value={getValue(product)}
                                options={createDropdownOptions(product.vonServiceRoles)}
                                property="displayName"
                                onChange={(value) => onSelectRole(value, product)}
                            />
                        </div>
                        <div>
                            {product.licenses != 0 && <span>{getActiveUsers(product)} av {product.licenses} licenser används</span>}
                        </div>
                    </div>
                })}
            </fieldset>
        </div>
    );
}